




















































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import {AgGridVue} from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';

import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { PriceTableService } from "@/services/PriceTableService";
import { OrderDetail } from '@/models/order/OrderDetail';

import _map from 'lodash/map';
import _each from 'lodash/each';
import _filter from 'lodash/filter';

@Component({
    components: {
        AgGridVue,
        VuePerfectScrollbar
    }
})
export default class OrderPriceTable extends Vue {
    @Prop({default: true}) data!: Array<OrderDetail>;
    @Prop() customerId!: number;
    @Prop() customerGuid!: string;
    
    private priceTableService: PriceTableService;

    constructor() {
        super();

        this.priceTableService = new PriceTableService();
    }

    popupActive: boolean = false;

    gridColumnApi: any = null;
    gridApi: any = null;
    gridOptions: any = null;
    columnDefs: any = null;
    defaultColDef: any = null;
    itemSelected: any = null;

    rowData: any = [];
    rowDataContracts: any = [];
    rowDataQuotations: any = [];

    settings: any = {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.70,
    };

    beforeMount(){
        this.gridOptions = {
        };

        this.columnDefs = [
            {
                headerName: "",
                field: "",
                width: 50,
                checkboxSelection: true,
                headerCheckboxSelectionFilteredOnly: false,
                headerCheckboxSelection: false,
            },
            {
                headerName: "Tên bảng giá",
                field: "name",
                width: 272
            },
            {
                headerName: "Sản phẩm",
                field: "productNames",
                width: 130
            },
        ];

        this.defaultColDef = { 
            editable: false,
        };
    }

    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
    }

    save(){
        this.popupActive = false;
    }

    close(){
        this.popupActive = false;
    }

    openPopup() {
        this.popupActive = true;
        this.rowData = [];
        this.search();
    }

    onPopupClose(value: boolean){
        
    }

    onFirstDataRendered(params: any) {
        params.api.sizeColumnsToFit();
    }

    onGridReady(params: any) {
        params.api.sizeColumnsToFit(); 
    }

    onRowSelected(params: any){
        let data = params.data;

        if (params.node.selected) {
            this.itemSelected = data;
        }
        else{
            this.itemSelected = null;
        }
    }

    @Emit('applyPriceTable')
    onApplyPriceTable(priceTable: any){
        return priceTable;
    }

    apply(){
        this.onApplyPriceTable(this.itemSelected);
        this.popupActive = false;
        this.itemSelected = null; 
    }

    private async search(){
        let productIds: any = [];

        if (this.data.length > 0) {
            productIds = _map(this.data, 'product.id');
        }

        let result = await this.priceTableService.getAll(this.customerGuid, this.customerId, productIds.join(','), "");

        if (result.code > 0) {
            var data = result.data;

            if (data) {
                let priceTables = data.priceTables;
                let contractQuotation = data.contractQuotation;

                _each(priceTables, (item: any)=>{
                    item.productNames = _map(item.details, 'code');
                });

                _each(contractQuotation, (item: any)=>{
                    item.productNames = _map(item.details, 'code');
                });

                this.rowData = priceTables;
                this.rowDataContracts = _filter(contractQuotation, (item: any)=>{
                    return item.type === 1; 
                });

                this.rowDataQuotations = _filter(contractQuotation, (item: any)=>{
                    return item.type === 0; 
                });
            }
        }
    }
}
