import axios from 'axios';
import {ActionResult} from "@/models/ActionResult";

export class PriceTableService {
    url = `${process.env.VUE_APP_PRODUCT_URL}api/v1/pricetables`;

    getAll(customerGuid: string, customerId: number, productIds: string, keyword: string, page = 1, pageSize = 20): Promise<ActionResult<any>> {

        return axios.get(`${this.url}/get-all`, {
            params: {
                customerGuid, customerId, productIds, keyword, page, pageSize
            }
        })
    }
}